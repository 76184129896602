var user = JSON.parse(localStorage.getItem("user"));
var index = 
// index (default user menu)
[
  {
    title: 'الصفحة الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'خدمة العملاء / اتصل بنا',
    route: 'feedback',
    icon: 'PhoneIcon',
  },
  {
    header: 'الخدمات الفعالة',
    ifServices: true
  },
  {
    header: 'الصفحات',
    ifServices: true
  },
  {
    title: 'عرض لفترة محدودة ⭐',
    route: 'Offer',
    icon: 'StarIcon',
  },
  {
    title: 'الحسابات الفرعية',
    route: 'admins',
    icon: 'ShieldIcon',
    per: "admins"
  },
  {
    title: 'الفواتير / الاشتراكات',
    route: 'subscribes',
    icon: 'DollarSignIcon',
    per: "subscribe"
  },
  {
    title: 'اجهزة البصمة',
    route: 'devices',
    icon: 'ServerIcon',
    per: "devices"
  },
  {
    title: 'اعدادات الحساب',
    route: 'settings',
    icon: 'SettingsIcon',
    per: "settings"
  },
  {
    title: 'ارشيف الرسائل',
    route: 'archive',
    icon: 'ArchiveIcon',
    per: "archive"
  },
  {
    title: 'اعدادات الـSMS',
    route: 'sms',
    icon: 'PhoneIcon',
    per: "alsl"
  },
  {
    title: 'التحكم بالمعلمين',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    children: [
        {
            title: 'استعراض المعلمين',
            route: 'lessons|teachers2',
        },
        {
            title: 'إضافة معلم جديد',
            route: 'lessons|teachers-add2',
        },
        {
            title: 'استيراد المعلمين',
            route: 'teachers-import',
        },
    ],
  },
  {
    title: 'التحكم بالطلاب',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    children: [
        {
            title: 'استعراض الطلاب',
            route: 'students|list2',
        },
        {
            title: 'استيراد/اضافة الطلاب',
            route: 'students|add',
        },
    ],
  },
  {
    title: 'قوالب الرسائل',
    route: 'templates',
    icon: 'MessageSquareIcon',
    per: "archive"
  },
  {
    title: 'اعدادات تطبيق الجوال',
    route: 'app-settings',
    icon: 'SmartphoneIcon',
  },
  {
    title: 'شرح الاستخدام',
    href: 'https://brmja.com/howto#tahdir-v3',
    icon: 'VideoIcon',
  },
  {
    title: 'تثبيت اداة المتصفح',
    route: 'extension',
    icon: 'DownloadIcon'
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon'
  },
], 
// students
students = [
  {
    title: 'الصفحة الرئيسية',
    route: 'students|home',
    icon: 'HomeIcon',
    per: "students|home"
  },
  {
    title: 'خدمة العملاء / اتصل بنا',
    route: 'feedback',
    icon: 'PhoneIcon',
  },
  {
    title: 'تحضير الطلاب',
    icon: 'CheckSquareIcon',
    per: "students",
    children: [
      {
        title: 'تحضير بالباركود',
        route: 'students|scan|barcode',
        per: "students|scan|manual"
      },
      {
        title: 'تحضير يدوي',
        route: 'students|scan|manual',
        per: "students|scan|barcode"
      },
      {
        title: 'تحضير جماعي للفصول',
        route: 'students|scan|bulk',
        per: "students|scan|bulk"
      },
      {
        title: 'شاشة الاستئذان',
        route: 'students|scan|end',
        per: "students|scan|endex"
      },
      {
        title: 'شاشة الانصرافات',
        route: 'students|scan|ends',
        per: "students|scan|ends"
      },
      {
        title: 'الملاحظات عند الدخول',
        route: 'students|scan|notes',
        per: "students|scan|ends"
      },
      {
        title: 'تحضير فترة ماضية',
        route: 'students|reports|attend-before',
        per: "students|reports|attend-before"
      },
    ],
  },
  {
    title: 'تقارير الطلاب',
    icon: 'PieChartIcon',
    per: "students|reports",
    children: [
      {
        title: 'الحضور والغياب اليومي',
        route: 'students|reports|daily',
        per: "students|reports|daily"
      },
      {
        title: 'تقارير فترة',
        route: 'students|reports|between',
        per: "students|reports|between"
      },
      {
        title: 'احصائيات الفصول',
        route: 'students|reports|classrooms',
        per: "students|reports|classrooms"
      },
      {
        title: 'اجراءات الغياب والتأخر',
        icon: 'EditIcon',
        per: "students|alerts",
        route: "students|alerts",
        children: [
          {
              title: 'اجراءات الغياب بدون عذر',
              route: 'students|alerts',
          },
          {
              title: 'اجراءات التأخر',
              route: 'students|alerts2',
          },
      ],
      },
    ],
  },
  {
    title: 'التحكم في الطلاب',
    icon: 'UsersIcon',
    per: "students",
    children: [
      {
        title: 'استعراض الطلاب',
        route: 'students|list',
        per: "students|students|view"
      },
      {
        title: 'اضافة واستيراد الطلاب',
        route: 'students|add',
        per: 'students|students|add'
      },
      {
        title: 'نقل الطلاب',
        route: 'students|move',
        per: "students|students|move"
      },
      {
        title: 'ارسال رسائل للطلاب',
        route: 'students-messages',
        per: "students|students|send"
      },
      {
        title: 'خطة التعليم الاسبوعي',
        route: 'students|weeklyplan',
        per: "students|weeklyplan"
      },
      {
        title: 'تقييمات الطلاب',
        route: 'students|rates',
        per: "students|rates"
      },
      {
        title: 'الملاحظات الصحية',
        route: 'students|health',
        per: "students|health"
      },
    ],
  },
  {
    title: 'ارشيف الرسائل',
    route: 'archive',
    icon: 'ArchiveIcon',
    per: "students|archive"
  },
  {
    title: 'الرد على الغياب',
    icon: 'MessageSquareIcon',
    per: "students|reasons",
    route: "students|reasons"
  },
  {
    title: 'المحول للوكيل',
    icon: 'ShareIcon',
    per: "forwards",
    route: "forwards"
  },
  {
    title: 'المحول للموجه',
    icon: 'ShareIcon',
    per: "students|forwards2",
    route: "forwards2"
  },
  {
    title: 'اجهزة البصمة',
    route: 'devices',
    icon: 'ServerIcon',
    per: "devices"
  },
  {
    title: 'ضبط الحضور والارسال',
    icon: 'SettingsIcon',
    per: "students|settings",
    children: [
      {
        title: 'اوقات الحضور والخروج',
        route: 'students|settings|groups'
      },
      {
        title: 'ارسال الغياب والتأخر',
        route: 'students|settings|send'
      },
      {
        title: 'اعدادات الـSMS',
        route: 'sms'
      },
      {
        title: 'الاجازات / العطلات',
        route: 'students|freedays'
      }
    ],
  },
  {
    title: 'المخالفات السلوكية',
    icon: 'UserXIcon',
    per: "students|bis",
    route: "students|bis"
  },
  {
    title: 'اجراءات الغياب والتأخر',
    icon: 'EditIcon',
    per: "students|alerts",
    route: "students|alerts",
    children: [
      {
          title: 'اجراءات الغياب بدون عذر',
          route: 'students|alerts',
      },
      {
          title: 'اجراءات التأخر',
          route: 'students|alerts2',
      },
  ],
  },
  {
    title: 'المواقف والزيارات',
    icon: 'CalendarIcon',
    per: "students|breaks-visits",
    children: [
      {
        title: 'المواقف اليومية',
        route: 'students|breaks'
      },
      {
        title: 'زيارات اولياء الامور',
        route: 'students|visits'
      }
    ],
  },
  {
    title: 'شهادات الشكر والتقدير',
    icon: 'CheckSquareIcon',
    per: "students|certs",
    route: "students|certs"
  },
  // {
  //   title: 'ساعات التطوع',
  //   icon: 'ClockIcon',
  //   route: "students|hours",
  //   per: "students|hours"
  // },
  {
    title: 'مصمم الكشوف',
    icon: 'EditIcon',
    route: 'mentors|desinger2',
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon'
  },
]

var whatsapp = [
  {
    title: 'الصفحة الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
    per: "home"
  },
  {
    title: 'فحص الجهاز',
    route: 'whatsapp|scan',
    icon: 'SmartphoneIcon',
    per: "whatsapp|scan"
  }
]

var calls = [
  {
    title: 'شاشة المناداة',
    route: 'calls|home',
    icon: 'MonitorIcon',
    per: "calls|home"
  },
  {
    title: 'المناداة يدوياً',
    route: 'calls|manual',
    icon: 'CheckSquareIcon',
    per: "calls|manual"
  },
  {
    title: 'طلبات التفعيل',
    route: 'calls|requests',
    icon: 'UserCheckIcon',
    per: "calls|requests"
  },
  {
    title: 'اعدادات المناداة',
    route: 'calls|settings',
    icon: 'SettingsIcon',
    per: "calls|settings"
  },
  {
    title: 'التحكم بالطلاب',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    children: [
        {
            title: 'استعراض الطلاب',
            route: 'students|list2',
        },
        {
            title: 'استيراد/اضافة الطلاب',
            route: 'students|add',
        },
    ],
  },
]

var results = [
  {
    title: 'الرئيسية',
    route: 'results|index',
    icon: 'HomeIcon',
    per: "results|index"
  },
  {
    title: 'ارسال الشهادات',
    route: 'results|home',
    icon: 'SendIcon',
    per: "results|home"
  },
  {
    title: 'العمليات السابقة',
    route: 'results|history',
    icon: 'ClockIcon',
    per: "results|history"
  },
  {
    title: 'احصائيات الشهادات',
    route: 'results|stats',
    icon: 'BarChartIcon',
    per: "results|stats"
  },
  {
    title: 'الرابط الموحد للشهادات',
    route: 'results|link',
    icon: 'LinkIcon',
    per: "results|link"
  },
  {
    title: 'ارشيف الرسائل',
    route: 'archive_results',
    icon: 'ArchiveIcon',
    per: "archive"
  },
  {
    title: 'استيراد الطلاب',
    route: 'students|add',
    icon: 'DownloadIcon',
    per: "results|import"
  },
  {
    title: 'تثبيت اداة المتصفح',
    route: 'extension',
    icon: 'DownloadIcon'
  },
]
var committees = [
  {
    title: 'الرئيسية',
    route: 'committees|home',
    icon: 'HomeIcon'
  },
  {
    title: 'التحكم بالطلاب',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    children: [
        {
            title: 'استعراض الطلاب',
            route: 'students|list2',
        },
        {
            title: 'استيراد/اضافة الطلاب',
            route: 'students|add',
        },
      {
        title: 'ادارة الفصول',
        route: 'students|move'
      },
    ],
  },
  {
    title: 'التحكم بالمعلمين',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    children: [
        {
            title: 'استعراض المعلمين',
            route: 'lessons|teachers2',
        },
        {
            title: 'إضافة معلم جديد',
            route: 'lessons|teachers-add2',
        },
        {
            title: 'استيراد المعلمين',
            route: 'teachers-import',
        },
    ],
  },
  {
    title: 'توزيع اللجان',
    route: 'committees|create',
    icon: 'GridIcon',
  },
  {
    title: 'سجل عمليات التوزيع',
    route: 'committees|history',
    icon: 'ListIcon',
  },
  {
    title: 'تقرير الحضور والغياب',
    route: 'committees|reports-all',
    icon: 'PieChartIcon',
  },
  {
    title: 'النماذج والكشوف',
    route: 'committees|kushof',
    icon: 'PrinterIcon',
  },
  {
    title: 'ارشيف الرسائل',
    route: 'archive_committees',
    icon: 'ArchiveIcon',
    per: "archive"
  },
  {
    title: 'الاعدادات',
    route: 'committees|settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'شروحات النظام',
    icon: 'VideoIcon',
    href: 'https://www.youtube.com/playlist?list=PLEurVG9qb-Cr30Ib4WfwVdKJw3NXB4RTA'
  },
]

var teachers = [
  {
    title: 'الرئيسية',
    route: 'teachers|home',
    icon: 'HomeIcon',
  },
  {
    title: 'خدمة العملاء / اتصل بنا',
    route: 'feedback',
    icon: 'PhoneIcon',
  },
  {
    title: 'تحضير المعلمين',
    route: 'teachers|scan',
    per: 'teachers|scan',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'التحكم بالمعلمين',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    children: [
        {
            title: 'استعراض المعلمين',
            route: 'teachers|list',
            per: "teachers|view"
        },
        {
            title: 'إضافة معلم جديد',
            route: 'teachers|add',
            per: "teachers|add"
        },
        {
            title: 'استيراد المعلمين',
            route: 'teachers-import',
            per: "teachers|add"
        },
    ],
  },
  {
      title: 'تقارير المعلمين',
      icon: 'PieChartIcon',
      tagVariant: 'light-warning',
      children: [
          {
              title: 'الحضور والغياب والتأخر',
              route: 'teachers|reports|daily',
              per: "teachers|reports|daily"
          },
          {
              title: 'الحضور والغياب.. لفترة',
              route: 'teachers|reports|between',
              per: "teachers|reports|between"
          },
      ],
  },
  {
    title: 'تقرير المعلمين التراكمي',
    route: 'teachers|fullreport',
    icon: 'FileTextIcon',
    per: "teachers|fullreport"
  },
  {
    title: 'جدول المناوبة',
    route: 'teachers|shifts',
    icon: 'CalendarIcon',
    per: "teachers|shifts"
  },
  {
    title: 'جدول الإشراف',
    route: 'teachers|shifts2',
    icon: 'CalendarIcon',
    per: "teachers|shifts2"
  },
  {
    title: 'ارسال رسالة للمعلمين',
    route: 'teachers|send',
    icon: 'MessageSquareIcon',
    per: "teachers|teachers|send"
  },
  {
      title: 'التعاميم',
      icon: 'SendIcon',
      tagVariant: 'light-warning',
      per: "teachers|mails",
      children: [
          {
              title: 'ارسال تعميم جديد',
              route: 'teachers|mails|send',
          },
          {
              title: 'استعراض التعاميم',
              route: 'teachers|mails|list',
          },
      ],
  },
  {
    title: 'شهادات الشكر والتقدير',
    route: 'teachers|certs',
    icon: 'EditIcon',
    per: "teachers|certs"
  },
  {
    title: 'الإعدادات',
    icon: 'SettingsIcon',
    per: "teachers|settings",
    children: [
      {
        title: 'البيانات العامة',
        route: 'teachers|settings|info'
      },
      {
        title: 'اوقات الحضور والخروج',
        route: 'teachers|settings|groups'
      },
      {
        title: 'ارسال التأخر التلقائي',
        route: 'teachers|settings|send'
      },
      {
        title: 'اعدادات الـSMS',
        route: 'sms'
      },
      {
        title: 'الاجازات / العطلات',
        route: 'teachers|freedays'
      },
      {
        title: 'حذف تقارير اليوم',
        route: 'teachers|delete-today'
      }
    ],
  },
  {
    title: 'اجهزة البصمة',
    route: 'devices',
    icon: 'ServerIcon',
    per: "devices"
  },
]

var lessons = [
  {
    title: 'الرئيسية',
    route: 'lessons|home',
    icon: 'HomeIcon'
  },
  {
    title: 'خدمة العملاء / اتصل بنا',
    route: 'feedback',
    icon: 'PhoneIcon',
  },
  {
    title: 'المحول للوكيل',
    icon: 'ShareIcon',
    per: "forwards",
    route: "forwards"
  },
  {
    title: 'المحول للموجه',
    icon: 'ShareIcon',
    per: "students|forwards2",
    route: "forwards2"
  },
  {
    title: 'تقارير الطلاب',
    icon: 'PieChartIcon',
    per: "lessons|report",
    route: "lessons|report"
  },
  {
    title: 'احصائيات الفصول',
    icon: 'BarChartIcon',
    per: "lessons|not-attended",
    route: "lessons|not-attended"
  },
  {
    title: 'التحكم بالمعلمين',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    children: [
        {
            title: 'استعراض المعلمين',
            route: 'lessons|teachers',
        },
        {
            title: 'إضافة معلم جديد',
            route: 'lessons|teachers-add',
        },
        {
            title: 'استيراد المعلمين',
            route: 'teachers-import',
        },
    ],
  },
  {
    title: 'الإعدادات',
    route: 'lessons|settings',
    icon: 'SettingsIcon',
    per: "lessons|settings"
  },
  {
    title: 'ارسال رسائل للطلاب',
    route: 'students-messages',
    per: "students|students|send"
  },
  {
    title: 'ارشيف الرسائل',
    route: 'archive_lessons',
    icon: 'ArchiveIcon',
    per: "archive"
  },
  {
    title: 'استيراد الطلاب',
    route: 'students|add',
    icon: 'DownloadIcon',
    per: "results|import"
  },
]

var xteacher = [
  {
    title: 'الرئيسية',
    route: 'xteacher|home',
    icon: 'HomeIcon'
  },
  {
    title: 'تحضير الحصص',
    route: 'xteacher|attend',
    icon: 'CheckSquareIcon'
  },
  {
    title: 'تقرير الغياب',
    route: 'xteacher|report',
    icon: 'PieChartIcon'
  },
  {
    title: 'خطة التعلم الاسبوعي',
    route: 'xteacher|weeklyplan',
    icon: 'CalendarIcon'
  },
  {
    title: 'تحويل طالب للوكيل',
    route: 'xteacher|forward',
    icon: 'ShareIcon'
  },
  {
    title: 'الحالات الصحية للطلاب',
    route: 'xteacher|health',
    icon: 'HeartIcon'
  },
  {
    title: 'تقييم الطلاب',
    route: 'xteacher|rate',
    icon: 'StarIcon'
  },
  {
    title: 'المهام',
    route: 'xteacher|tasks',
    icon: 'ClockIcon'
  },
  {
    title: 'التعاميم',
    route: 'xteacher|mails',
    icon: 'BellIcon'
  },
  {
    title: 'المساءلات',
    route: 'xteacher|asks',
    icon: 'MailIcon'
  },
  {
    title: 'تقرير الحضور الخاص بي',
    route: 'xteacher|myreport',
    icon: 'FileIcon'
  },
  {
    title: 'تسجيل الخروج',
    route: 'xteacher|logout',
    icon: 'LogOutIcon'
  },
]

var mentors = [
  {
    title: 'الرئيسية',
    route: 'mentors|home',
    icon: 'HomeIcon'
  },
  {
    title: 'مصمم الكشوف',
    icon: 'EditIcon',
    route: 'mentors|desinger',
  },
  {
    title: 'قائمة المشكلات',
    icon: 'FileIcon',
    route: 'mentors|create',
  },
  {
    title: 'الاستبانات',
    icon: 'FileIcon',
    route: 'mentors|create21',
  },
  {
    title: 'النماذج',
    icon: 'FileIcon',
    route: 'mentors|create22',
  },
  {
    title: 'كشوف المتابعة',
    route: 'mentors|kushof',
    icon: 'FileIcon'
  },
  {
    title: 'السجلات المدرسية',
    route: 'mentors|files',
    icon: 'FileIcon'
  },
  {
    title: 'ارشيف الرسائل',
    route: 'archive_mentors',
    icon: 'ArchiveIcon',
    per: "archive"
  },
]

var table = [
  {
    title: 'الرئيسية',
    route: 'table|home',
    icon: 'HomeIcon'
  },
  {
    title: 'إنشاء الجدول الآن',
    route: 'table|create',
    icon: 'CalendarIcon',
    per: "table|create"
  },
  {
    title: 'الجداول المحفوظة',
    route: 'table|history',
    icon: 'ClockIcon',
    per: "table|history"
  },
  {
    title: 'الإعدادات العامة',
    route: 'table|settings',
    icon: 'SettingsIcon',
    per: "table|settings"
  },
  {
    title: 'ادارة المعلمين',
    route: 'table|teachers',
    icon: 'UsersIcon',
    per: "table|teachers"
  },
  {
    title: 'ادارة الفصول',
    route: 'table|classrooms',
    icon: 'ListIcon',
    per: "table|classrooms"
  },
  {
    title: 'ادارة المواد',
    route: 'table|subjects',
    icon: 'BookIcon',
    per: "table|subjects"
  },
  {
    title: 'حصص الانتظار',
    route: 'table|waiting',
    icon: 'WatchIcon',
    per: "table|waiting"
  },
  {
    title: 'الشاشة المباشرة',
    route: 'table|realtime',
    icon: 'MonitorIcon',
    per: "table|realtime"
  },
]

var arr = index;

if(window.location.pathname.includes("_students")){
  arr = students;
}
if(window.location.pathname.includes("_whatsapp")){
  arr = whatsapp;
}
if(window.location.pathname.includes("_calls")){
  arr = calls;
}
if(window.location.pathname.includes("_results")){
  arr = results;
}
if(window.location.pathname.includes("_committees")){
  arr = committees;
}
if(window.location.pathname.includes("_teachers")){
  arr = teachers;
}
if(window.location.pathname.includes("_lessons")){
  arr = lessons;
}
if(window.location.pathname.includes("_xteacher")){
  arr = xteacher;
}
if(window.location.pathname.includes("_mentors")){
  arr = mentors;
}
if(window.location.pathname.includes("_table")){
  arr = table;
}

var arr2 = [];

arr.forEach(function(a){
  var add = false;
  if(!a.per){
    add = true
  }else{
    if(checkPer(a.per)){
      add = true
    }
  }
  if(add){
    if(a.children){
      var a2 = [];
      a.children.forEach(function(b){
        var add2 = false;
        if(!b.per){
          add2 = true
        }else{
          if(checkPer(b.per)){
            add2 = true
          }
        }
        if(add2){
          a2.push(b)
        }
      })
      a.children = a2;
    }
  }
  if(a.ifServices && window.location.pathname != '/'){
    add = false
  }
  if(add){ arr2.push(a); }
})

export default arr2
